<template>
  <div class="home-performers home-card home-card--performers">
    <div class="home-card__inner">
      <h3 class="home-card__title">
        <div class="home-card__title__inner">
          <span v-fit-text class="home-card__title__text heading heading--1-bold heading--highlighted">Előadók</span>
        </div>
      </h3>

      <HorizontalScroller class="home-card__scroller" :is-silent="true">
        <div class="home-card__scroller__inner">
          <div class="home-card__scroller__inner__content">
            <ul class="home-performers__list home-card__list content-list content-list--performers content-list--horizontal content-list--guttered">
              <li
                v-for="performer in performers"
                :key="`home-performers-list-${performer.id || performer.slug}`"
                class="content-list__item"
              >
                <PerformerCard
                  :performer="performer"
                  size="medium"
                  class="content-list__card"
                />
              </li>
            </ul>
          </div>
          <div class="home-card__scroller__inner__spacer" aria-hidden="true" />
        </div>
      </HorizontalScroller>

      <div class="home-card__footer">
        <BaseButton
          icon="arrow-top-right"
          tag="nuxt-link"
          to="/eloadok"
          size="small"
          class="home-card__footer__button"
        >
          Tovább az Előadókhoz
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import PerformerCard from '../PerformerCard.vue'

export default {

  components: {
    PerformerCard
  },

  data: () => ({
    performers: []
  }),

  async created () {
    this.performers = await this._getPerformers()
  },

  methods: {
    async _getPerformers () {
      const { data } = await this.$getPerformers(10, 1, 10)
      return data?.data || []
    }
  }

}
</script>
