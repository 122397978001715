<template>
  <nuxt-link
    v-if="sponsorship"
    :to="contentUrl"
    class="sponsorship-card"
    :class="`sponsorship-card--${size}`"
  >
    <div
      class="sponsorship-card__thumbnail program-office-thumbnail js-mouse-trackable"
      :class="`program-office-thumbnail--${size}`"
      :style="{
        ...mouseCoordsCssCustomProperties,
        '--bg-img': programOffice.logo_background && programOffice.logo_background.url ? `url('${programOffice.logo_background.url}')` : null
      }"
      @mouseenter="setMouseCoords($event)"
      @mousemove="throttledCoordsSetter($event)"
      @mouseleave="coordsResetter()"
    >
      <div class="program-office-thumbnail__inner">
        <img v-if="programOffice.logo_svg && programOffice.logo_svg.url" :src="programOffice.logo_svg.url" alt="" class="program-office-thumbnail__logo">
        <!-- <SkeletonBlock :is-loading="isLoading" skeleton-class="program-office-thumbnail__image" width="100%" height="360"></SkeletonBlock> -->
      </div>
    </div>
    <div class="sponsorship-card__content">
      <div class="sponsorship-card__title" :class="size === 'small' ? 'title title--5' : 'heading heading--3-bold'">
        {{ sponsorship.title }}
      </div>
      <div v-if="sponsorship.short_description" class="sponsorship-card__description body-text">
        {{ sponsorship.short_description }}
      </div>
      <div class="sponsorship-card__button">
        <BaseButton size="small" tag="span" icon="arrow-right" icon-position="right">
          Tovább a támogatásra
        </BaseButton>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import throttle from 'just-throttle'
import MouseCoords from '../library/mixins/MouseCoords'

const AVAILABLE_SIZES = ['small', 'medium']

export default {

  mixins: [
    MouseCoords
  ],

  props: {
    size: { type: String, default: 'medium', validator: value => AVAILABLE_SIZES.includes(value) },
    isLoading: { type: Boolean, default: false },
    sponsorship: { type: Object, default: () => ({}) }
  },

  data: () => ({
    throttledCoordsSetter: throttle(function (e = null) {
      this.setMouseCoords(e)
    }, 50, { trailing: true })
  }),

  computed: {
    contentUrl () {
      return this.sponsorship?.slug ? `/tamogatasok/${this.sponsorship.slug}` : ''
    },

    programOffice () {
      return this.sponsorship?.program_office || {}
    }
  },

  methods: {
    coordsResetter () {
      this.throttledCoordsSetter.cancel()
      this.resetMouseCoords()
    }
  }

}
</script>
