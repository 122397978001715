<template>
  <div class="home-hero" :class="`home-hero--${isPlayable ? 'playable' : 'unplayable'}`">
    <div ref="preAnimTitle" class="home-hero__pre-animation">
      <div class="home-hero__pre-animation__title heading heading--1-bold heading--non-responsive container">
        <span ref="preAnimTitleInner" class="home-hero__pre-animation__title__inner">Hajógyár</span>
      </div>
    </div>

    <div ref="title" class="home-hero__title">
      <div ref="titleContainer" class="home-hero__title__container heading heading--1-bold heading--non-responsive container">
        <svg
          class="home-hero__title__text home-hero__title__text--upper"
          width="750"
          height="90"
          viewBox="0 0 750 90"
        >
          <mask id="upperTitleMask">
            <rect
              ref="upperTitleMask"
              x="-10%"
              y="-50%"
              width="10%"
              height="200%"
              transform="skewX(-30)"
              fill="white"
              class="home-hero__title__mask home-hero__title__mask--upper"
            />
          </mask>
          <g ref="upperTitleGroup" class="home-hero__title__group">
            <text class="home-hero__title__layer home-hero__title__layer--outline" x="0" y="85">Popkultúra</text>
            <text class="home-hero__title__layer home-hero__title__layer--background" x="0" y="85" mask="url(#upperTitleMask)">Popkultúra</text>
          </g>
        </svg>
        <svg
          class="home-hero__title__text home-hero__title__text--lower"
          width="750"
          height="90"
          viewBox="0 0 750 90"
        >
          <mask id="lowerTitleMask">
            <rect
              ref="lowerTitleMask"
              x="-10%"
              y="-50%"
              width="10%"
              height="200%"
              transform="skewX(-30)"
              fill="white"
              class="home-hero__title__mask home-hero__title__mask--lower"
            />
          </mask>
          <g ref="lowerTitleGroup" class="home-hero__title__group">
            <text class="home-hero__title__layer home-hero__title__layer--outline" x="750" y="85" text-anchor="end">360 fokban</text>
            <text class="home-hero__title__layer home-hero__title__layer--background" x="750" y="85" text-anchor="end" mask="url(#lowerTitleMask)">360 fokban</text>
          </g>
        </svg>
      </div>
    </div>

    <div ref="logo" class="home-hero__logo">
      <div class="home-hero__logo__container container">
        <div ref="mediaWrapper" class="home-hero__logo__wrapper">
          <video
            ref="video"
            src="/video/hero.mp4"
            class="home-hero__logo__media home-hero__logo__media--video"
            playsinline
            autoplay
            muted
            preload="auto"
            disablepictureinpicture
            disableremoteplayback
            @loadedmetadata="handleVideoLoad"
          />
          <img
            src="/video/hero.jpg"
            alt=""
            class="home-hero__logo__media home-hero__logo__media--image"
          >
        </div>
      </div>
    </div>

    <TopOffsetSizer ref="topOffsetSizer" />
  </div>
</template>

<script>
import zenscroll from 'zenscroll'
import { disablePageScroll, enablePageScroll, clearQueueScrollLocks } from 'scroll-lock'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {

  props: {
    isAnimating: { type: Boolean, default: false }
  },

  data: () => ({
    isPlayable: true,
    baseTimeline: null,
    videoScrubTimeline: null
  }),

  async mounted () {
    await this.$nextTick()
    this._initBaseTimeline()
    this._initVideoTimelines()
  },

  methods: {
    _initBaseTimeline () {
      const { preAnimTitle, preAnimTitleInner, upperTitleGroup, upperTitleMask, lowerTitleGroup, lowerTitleMask } = this.$refs

      this.baseTimeline = gsap.timeline()

      if (this.isAnimating) {
        this.baseTimeline
          .call(this._disablePageScroll)
          .fromTo(preAnimTitleInner, { translateY: '100%' }, { translateY: 0, duration: 0.75, ease: 'Sine.easeOut' })
          .to(preAnimTitleInner, { translateY: '-100%', duration: 1, delay: 1, ease: 'Expo.easeOut' })
      }

      const [duration, delay] = this.isAnimating ? [0.5, undefined] : [0, 0]

      this.baseTimeline
        .fromTo(preAnimTitle, { opacity: 1 }, { opacity: 0, duration: duration * 2, display: 'none' })
        .call(this._enablePageScroll)
        .call(this._setReady)
        .fromTo(upperTitleGroup, { translateY: '100%' }, { translateY: 0, duration, ease: 'Sine.easeOut' }, delay)
        .to(upperTitleMask, { attr: { x: '120%' }, duration, ease: 'Sine.easeIn' }, delay)
        .fromTo(lowerTitleGroup, { translateY: '100%' }, { translateY: 0, duration, ease: 'Sine.easeOut' }, delay)
        .to(lowerTitleMask, { attr: { x: '0%', width: '120%' }, duration, ease: 'Sine.easeIn' }, delay)
        .call(this._scrollDown)
    },

    _initVideoTimelines () {
      const { topOffsetSizer, logo, title, titleContainer } = this.$refs
      const topOffset = parseInt(getComputedStyle(topOffsetSizer.$el).width, 10)

      const videoOptions = {
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: this.$el,
          start: () => `top ${topOffset}`,
          end: () => `bottom ${topOffset}`,
          scrub: 1
        }
      }

      this.videoScrubTimeline = gsap.timeline(videoOptions)

      this.videoScrubTimeline
        .fromTo(title, { opacity: 1 }, { opacity: 0, duration: 0.5, height: '50%', display: 'none' }, 0.15)
        .fromTo(logo, { opacity: 1 }, { opacity: 0, duration: 0.5, display: 'none' }, 0.5)
        .to(titleContainer, { translateY: 0, duration: 0.25 }, 0)
    },

    handleVideoLoad (e) {
      const { mediaWrapper, video } = this.$refs

      // the `autoplay` attribute is needed for mobile Safari,
      // but we don't _actually_ want to autoplay the video!
      video.autoplay = false

      this.videoScrubTimeline
        .fromTo(video, { currentTime: 0 }, { currentTime: video.duration || 1, duration: 0.25 }, 0)
        .to(mediaWrapper, { translateY: '-20%', scale: 0.85, duration: 0.5 }, 0.15)

      setTimeout(() => { this._detectPlayability(video) }, 1000)
    },

    async _detectPlayability (videoEl) {
      // a.k.a. Low Power Mode on iOS
      this.isPlayable = true
      videoEl.play().catch((e) => { this.isPlayable = false })
      await this.$nextTick()
      videoEl.pause()
      videoEl.currentTime = 0
    },

    _setReady () {
      this.$bus.$emit('HOMEPAGE.SET_READY')
    },

    _scrollDown () {
      zenscroll.toY(this.$el?.clientHeight, 2000)
    },

    _enablePageScroll () {
      setTimeout(() => {
        clearQueueScrollLocks()
        enablePageScroll()
      }, 100)
    },

    _disablePageScroll () {
      setTimeout(() => {
        disablePageScroll()
      }, 100)
    }
  }

}
</script>
