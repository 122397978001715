<template>
  <main role="main" class="home-page" :class="{ 'home-page--ready': isReady }">
    <HomeHero :is-animating="isHeroAnimationPlayable" class="home-page__hero" />

    <div class="home-page__content">
      <HomeSectionIndex class="home-page__index" />
      <HomeNews class="home-page__news" />
      <HomeCardStack class="home-page__card-stack" />
    </div>
  </main>
</template>

<script>
import smoothScrollTop from '../library/utils/smoothScrollTop'

import HomeHero from '../components/Home/Hero.vue'
import HomeSectionIndex from '../components/Home/SectionIndex.vue'
import HomeNews from '../components/Home/News.vue'
import HomeCardStack from '../components/Home/CardStack.vue'

export default {

  name: 'Home',

  components: {
    HomeHero,
    HomeSectionIndex,
    HomeNews,
    HomeCardStack
  },

  beforeRouteEnter (to, from, next) {
    const isFirstPageVisited = !(from?.matched || []).length
    next((vm) => { vm.isHeroAnimationPlayable = isFirstPageVisited })
  },

  data: () => ({
    isHeroAnimationPlayable: false,
    isReady: false
  }),

  head () {
    return {
      bodyAttrs: { class: 'page:home' }
    }
  },

  mounted () {
    setTimeout(() => { smoothScrollTop(0, 0) }, 100)

    this.$bus.$on('HOMEPAGE.SET_READY', () => { this.isReady = true })
  }

}
</script>
