<template>
  <component
    :is="tag"
    v-if="article"
    v-bind="attr"
    class="article-card js-hoverable"
    :class="[ `article-card--${size}` ]"
  >
    <div class="article-card__thumbnail">
      <div class="article-card__thumbnail__inner">
        <SkeletonBlock :is-loading="isLoading" skeleton-class="article-card__thumbnail__image" aspect-ratio="2 / 3">
          <LazyImage
            v-if="article.thumbnail && article.thumbnail.url"
            :src="$imgproxy(article.thumbnail.url, { width: size === 'large' ? 800 : 400 })"
            :srcset="`${$imgproxy(article.thumbnail.url, { width: size === 'large' ? 800 : 400, isRetina: true })} 2x`"
            class="article-card__thumbnail__image"
            alt=""
          />
        </SkeletonBlock>
      </div>
    </div>

    <div class="article-card__content">
      <div v-if="isExternal" class="article-card__source title title--6">
        <span class="article-card__source__url">{{ baseDomain }}</span>
        <SvgIcon
          name="external-link"
          class="article-card__source__icon"
          size="m"
        />
      </div>

      <div class="article-card__meta">
        <template v-if="isLoading">
          <SkeletonBlock v-for="n in 2" :key="n" width="85px" height="20px" :is-inline="true" />
        </template>
        <template v-else>
          <CategoryLabel
            :category="article.category"
            class="article-card__meta__label"
          />
          <span v-if="article.date" class="article-card__meta__label label label--small-date">
            {{ $formatDate(article.date, { isCompact: true }) }}
          </span>
        </template>
      </div>

      <h3
        class="article-card__title"
        :class="size === 'large' ? 'heading heading--2' : 'title title--6'"
      >
        <template v-if="isLoading">
          <SkeletonBlock width="90%" :height="size === 'large' ? '1.5em' : '1em'" :is-inline="true" />
          <SkeletonBlock width="40%" :height="size === 'large' ? '1.5em' : '1em'" :is-inline="true" />
        </template>
        <span v-else class="article-card__title__inner">
          {{ article.title }}
        </span>
      </h3>

      <CollapseTransition v-if="!isLoading && size === 'large'">
        <div v-if="isHovered && article.lead" class="article-card__description body-text">
          <div class="article-card__description__inner">
            {{ article.lead }}
          </div>
        </div>
      </CollapseTransition>
    </div>
  </component>
</template>

<script>
import HoverableCard from '../library/mixins/HoverableCard'

const AVAILABLE_SIZES = ['small', 'medium', 'large']

export default {

  mixins: [
    HoverableCard
  ],

  props: {
    isLoading: { type: Boolean, default: false },
    size: { type: String, default: 'medium', validator: value => AVAILABLE_SIZES.includes(value) },
    url: { type: String, default: null },
    article: { type: Object, default: () => ({}) }
  },

  computed: {
    isExternal () {
      return !!(this.article?.url || '').trim()
    },

    tag () {
      return this.isExternal ? 'a' : 'nuxt-link'
    },

    attr () {
      return this.isExternal
        ? { href: this.article.url, target: '_blank' }
        : { to: `/hirek/${this.article?.slug}` }
    },

    baseDomain () {
      if (!this.isExternal) { return null }
      return (new URL(this.article?.url)).hostname
    }
  }

}
</script>
