<template>
  <div
    class="home-section-index"
    :class="{ 'home-section-index--hidden': !currentActive }"
  >
    <div class="home-section-index__inner">
      <ol
        v-for="(section, i) in sections"
        :key="section.id"
        class="home-section-index__list"
      >
        <li
          class="home-section-index__item"
          :class="{ 'home-section-index__item--current': isActive(section) }"
        >
          <button type="button" class="home-section-index__item__button" @click="scrollToSection(section)">
            <div class="home-section-index__item__indicator" />
            <div class="home-section-index__item__number">
              {{ String(`${i + 1}`).padStart(2, '0') }}
            </div>
            <div class="home-section-index__item__title">
              {{ section.title }}
            </div>
          </button>
        </li>
      </ol>
    </div>

    <TopOffsetSizer ref="topOffsetSizer" />
  </div>
</template>

<script>
import last from 'just-last'
import zenscroll from 'zenscroll'

const SECTION_ID_DATA_ATTR = 'data-section-id'

// we'll consider something visible if 50% of it is intersecting the viewport
const INTERSECTION_THRESHOLD = 0.5

export default {

  data: () => ({
    sections: [
      { id: 'news', title: 'Hírfolyam' },
      { id: 'episodes', title: 'Kontent' },
      { id: 'events', title: 'Események' },
      { id: 'performers', title: 'Előadók' },
      { id: 'program-offices', title: 'Támogatások' }
    ],
    progress: {},
    observer: null
  }),

  computed: {
    currentActive () {
      return last(Object.entries(this.progress).filter(([_, value]) => value >= INTERSECTION_THRESHOLD))?.[0]
    }
  },

  mounted () {
    const sectionEls = [...document.querySelectorAll(`[${SECTION_ID_DATA_ATTR}]`)]

    this.observer = new IntersectionObserver(this._handleObserving, { threshold: [...new Array(101)].map((_, i) => i / 100) })
    sectionEls.forEach((el, i) => { this.observer.observe(el) })
  },

  methods: {
    isActive (section) {
      return section?.id === this.currentActive
    },

    _handleObserving (entries) {
      entries.forEach((entry) => {
        const { intersectionRect, rootBounds } = entry
        const { sectionId } = entry.target.dataset
        const rootIntersectionRatio = intersectionRect?.height / rootBounds?.height

        this.$set(this.progress, sectionId, Math.max(entry.intersectionRatio, rootIntersectionRatio))
      })
    },

    async scrollToSection (section) {
      const originalTop = window.pageYOffset
      const topOffset = parseInt(getComputedStyle(this.$refs.topOffsetSizer.$el).width, 10)

      await this.$nextTick()
      window.scrollTo(0, 0)

      await this.$nextTick()
      const sectionEl = document.querySelector(`[${SECTION_ID_DATA_ATTR}="${section.id}"]`)
      const targetTop = sectionEl.getBoundingClientRect().top - topOffset
      window.scrollTo(0, originalTop)

      await this.$nextTick()
      zenscroll.toY(targetTop, 1000)
    }
  }

}
</script>
