<template>
  <nuxt-link
    v-if="performer && Object.keys(performer)"
    :to="`/eloadok/${performer.slug}`"
    class="performer-card"
    :class="[ `performer-card--${size}` ]"
  >
    <div class="performer-card__thumbnail">
      <div class="performer-card__thumbnail__inner">
        <LazyImage
          v-if="thumbnailUrl"
          :src="$imgproxy(thumbnailUrl, { width: thumbnailSize, height: thumbnailSize, resizeMethod: 'fill' })"
          :srcset="`${$imgproxy(thumbnailUrl, { width: thumbnailSize, height: thumbnailSize, resizeMethod: 'fill', isRetina: true })} 2x`"
          class="performer-card__thumbnail__image"
          :alt="`${performer.title} profilképe`"
        />
        <SvgIcon
          v-else-if="size === 'small'"
          name="profile"
          size="xxl"
          class="performer-card__thumbnail__image"
        />
      </div>
    </div>
    <div
      class="performer-card__name"
      :class="size === 'small' ? 'title title--5' : 'heading heading--1-bold'"
    >
      <span class="performer-card__name__inner">{{ performer.title }}</span>
    </div>
    <div class="performer-card__genres">
      <GenreLabel
        v-for="genre in performer.genres"
        :key="`performer-card-${performer.id}-genre-${genre.slug}`"
        :genre="genre"
        class="performer-card__genres__item"
      />
    </div>
  </nuxt-link>
</template>

<script>
const AVAILABLE_SIZES = ['small', 'medium']

export default {

  props: {
    size: { type: String, default: 'medium', validator: value => AVAILABLE_SIZES.includes(value) },
    performer: { type: Object, required: true }
  },

  computed: {
    thumbnailUrl () {
      return this.performer?.thumbnail?.url || null
    },

    thumbnailSize () {
      return this.size === 'small' ? 200 : 400
    }
  }

}
</script>
