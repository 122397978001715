<template>
  <div class="home-card-stack">
    <div class="home-card-stack__container container">
      <div
        v-for="(card, i) in cards"
        ref="card"
        :key="`home-card${card}`"
        :data-card-index="i"
        class="home-card-stack__item"
        :class="`home-card-stack__item--${card} home-card-stack__item--${i}`"
        :style="(cardStyles || [])[i]"
      >
        <component
          :is="`home-${card}`"
          class="home-card-stack__card"
          :data-section-id="card"
        />
      </div>
    </div>
  </div>
</template>

<script>
import last from 'just-last'

import HomeEpisodes from './Episodes.vue'
import HomeEvents from './Events.vue'
import HomePerformers from './Performers.vue'
import HomeProgramOffices from './ProgramOffices.vue'

export default {

  components: {
    HomeEpisodes,
    HomeEvents,
    HomePerformers,
    HomeProgramOffices
  },

  data: () => ({
    cards: ['episodes', 'events', 'performers', 'program-offices'],
    progress: [],
    isScrolledPast: false,
    cardIntersectionObserver: null
  }),

  computed: {
    cardStyles () {
      return this.cards.map((_, i) => {
        return `
          --i: ${i};
          --remaining: ${this.cards.length - i};
          --fade-progress: ${this.progress?.[i + 1] || 0};
        `
      })
    }
  },

  mounted () {
    this._addScrollListeners()
    this._addCardObservers()
    this._updateScrollPastVariable()
    this._triggerObservers()
  },

  beforeDestroy () {
    this._removeScrollListeners()
    this._removeCardObservers()
  },

  methods: {
    _addScrollListeners () {
      window.addEventListener('scroll', this._updateScrollPastVariable)
    },

    _removeScrollListeners () {
      window.removeEventListener('scroll', this._updateScrollPastVariable)
    },

    _addCardObservers () {
      this.cardIntersectionObserver = new IntersectionObserver(this._handleCardObserving, { threshold: [...new Array(101)].map((_, i) => i / 100) })

      this.cards.forEach((_, i) => {
        this.cardIntersectionObserver.observe(this.$refs?.card?.[i])
      })
    },

    _removeCardObservers () {
      this.cardIntersectionObserver.disconnect()
    },

    _updateScrollPastVariable () {
      this.isScrolledPast = this.$el?.getBoundingClientRect()?.bottom <= window.innerHeight
    },

    async _triggerObservers () {
      await this.$nextTick()
      const originalTop = window.pageYOffset
      await this.$nextTick()
      window.scrollTo(0, originalTop - 100)
      setTimeout(() => { window.scrollTo(0, originalTop) }, 1)
    },

    _handleCardObserving (entries) {
      const entry = last(entries || [])
      const cardIndex = parseInt(entry.target.dataset.cardIndex, 10)
      const progress = Math.round((entry.intersectionRect?.height / entry.boundingClientRect?.height) * 100)

      if (this.isScrolledPast) {
        // build a progress array with everything being 100, except the last index
        this._buildProgressArray(this.cards.length, 0)
      } else {
        this._buildProgressArray(cardIndex, progress)
      }
    },

    _buildProgressArray (currentCardIndex, currentCardProgress) {
      const res = [...new Array(this.cards.length)].map((_, i) => i <= currentCardIndex ? 100 : 0)
      res[currentCardIndex] = currentCardProgress || 0
      this.progress = res
    }
  }

}
</script>
