<template>
  <div class="home-program-offices home-card home-card--program-offices">
    <div class="home-card__inner">
      <h3 class="home-card__title">
        <div class="home-card__title__inner">
          <span v-fit-text class="home-card__title__text heading heading--1-bold heading--highlighted">Támogatások</span>
        </div>
      </h3>

      <HorizontalScroller v-if="(sponsorships || []).length" class="home-card__scroller" :is-silent="true">
        <div class="home-card__scroller__inner">
          <div class="home-card__scroller__inner__content">
            <ul class="home-program-offices__outer-list home-card__list content-list content-list--horizontal content-list--guttered">
              <li
                v-for="(group, groupNo) in groupedSponsorships"
                :key="`home-program-offices-list-group-${groupNo}`"
                class="content-list__item"
              >
                <div class="home-program-offices__inner-list content-list content-list--guttered">
                  <div
                    v-for="sponsorship in group"
                    :key="`home-program-offices-list-group-${groupNo}-item-${sponsorship.id || sponsorship.slug}`"
                    class="content-list__item"
                  >
                    <SponsorshipCard
                      :sponsorship="sponsorship"
                      size="small"
                      class="content-list__card"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="home-card__scroller__inner__spacer" aria-hidden="true" />
        </div>
      </HorizontalScroller>

      <div v-else class="home-card__empty">
        <EmptyContent title="Sajnos jelenleg nincsen elérhető támogatás!" />
      </div>

      <div v-if="(sponsorships || []).length" class="home-card__footer">
        <BaseButton
          icon="arrow-top-right"
          tag="nuxt-link"
          to="/tamogatasok"
          size="small"
          class="home-card__footer__button"
        >
          Tovább a támogatásokhoz
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import split from 'just-split'
import SponsorshipCard from '../SponsorshipCard.vue'

export default {

  components: {
    SponsorshipCard
  },

  data: () => ({
    sponsorships: []
  }),

  computed: {
    groupedSponsorships () {
      return split((this.sponsorships || []), 2)
    }
  },

  async created () {
    this.sponsorships = await this._getSponsorships()
  },

  methods: {
    async _getSponsorships () {
      const { data } = await this.$getSponsorships(10, 1, 10)
      return data?.data || []
    }
  }

}
</script>
