<template>
  <div class="home-news" data-section-id="news">
    <div ref="bg" class="home-news__bg" />
    <div ref="container" class="home-news__container container">
      <h1 class="home-news__title">
        <span v-fit-text class="home-news__title__inner heading heading--1-bold heading--highlighted">
          Hírfolyam
        </span>
      </h1>

      <ul class="home-news__list content-list content-list--articles content-list--freeflow content-list--guttered">
        <li
          v-for="(article, i) in articles"
          :key="`article-list-${article.id || article.slug}`"
          class="home-news__list__item content-list__item"
          :class="`home-news__list__item--${i + 1} content-list__item--${sizes[i]}`"
        >
          <ArticleCard
            :article="article"
            size="medium"
            class="home-news__list__card home-news__list__card--medium content-list__card"
          />
          <ArticleCard
            :article="article"
            size="large"
            class="home-news__list__card home-news__list__card--large content-list__card"
          />
        </li>
      </ul>

      <div class="home-news__footer">
        <BaseButton
          icon="arrow-top-right"
          tag="nuxt-link"
          to="/hirek"
          size="small"
          class="home-news__footer__button"
        >
          További hírek
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ArticleCard from '../ArticleCard.vue'

gsap.registerPlugin(ScrollTrigger)

export default {

  components: {
    ArticleCard
  },

  data: () => ({
    articles: [],
    sizes: [100, 66, 33, 50, 50]
  }),

  async created () {
    this.articles = await this._getNews()
  },

  mounted () {
    const scrollTrigger = {
      trigger: this.$el,
      start: 'top bottom',
      end: 'top center',
      scrub: 1
    }

    const containerWidth = `${this.$refs.container.offsetWidth / 1.35}px`

    gsap.fromTo(
      this.$refs.bg,
      { maxWidth: containerWidth, opacity: 0.5 },
      { maxWidth: '100%', opacity: 1, scrollTrigger }
    )

    gsap.to(
      this.$refs.bg,
      { borderRadius: 0, scrollTrigger: { ...scrollTrigger, start: 'top 75%' } }
    )

    gsap.fromTo(
      this.$refs.container,
      { translateY: '-2rem', scaleX: '65%' },
      { translateY: 0, scaleX: '100%', scrollTrigger }
    )
  },

  methods: {
    async _getNews () {
      const { data } = await this.$getNews(5, 1, 5)
      return data?.data || []
    }
  }

}
</script>
