<template>
  <div class="home-episodes home-card home-card--episodes">
    <div class="home-card__inner">
      <h3 class="home-card__title">
        <div class="home-card__title__inner">
          <span v-fit-text class="home-card__title__text heading heading--1-bold heading--highlighted">Kontent</span>
        </div>
      </h3>

      <HorizontalScroller class="home-card__scroller" :is-silent="true">
        <div class="home-card__scroller__inner">
          <div class="home-card__scroller__inner__content">
            <ul class="home-episodes__list home-card__list content-list content-list--episodes content-list--horizontal content-list--guttered">
              <li
                v-for="episode in episodes"
                :key="`home-episodes-list-${episode.id || episode.slug}`"
                class="home-episodes__item content-list__item"
              >
                <EpisodeCard
                  :episode="episode"
                  :image-width="800"
                  :is-rounded="true"
                  :is-external="true"
                  class="home-episodes__card content-list__card"
                />
              </li>
            </ul>
          </div>
          <div class="home-card__scroller__inner__spacer" aria-hidden="true" />
        </div>
      </HorizontalScroller>

      <div class="home-card__footer">
        <BaseButton
          icon="arrow-top-right"
          tag="a"
          :href="$config.PUBLIC_SITE_KONTENT_URL"
          target="_blank"
          size="small"
          class="home-card__footer__button"
        >
          Tovább a Kontentre
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data: () => ({
    episodes: []
  }),

  async created () {
    this.episodes = await this._getEpisodes()
  },

  methods: {
    async _getEpisodes () {
      const { data } = await this.$getEpisodesByMediaType(null, 10, 1, 10)
      return data?.data || []
    }
  }

}
</script>
